const validators = {
  email: (value) =>
    value.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    ),
  phone: (value) => value.match(/\+?[0-9]+/),
  cuitNumber: (value) => value.match(/^(0|[1-9]\d*)$/),
  name: (value) =>
    value.match(/^[a-zñáéíóúü\s]+$/i) && value.length > 1 && value.length < 21,
  company: (value) => value.length < 50,
  password: (value) => value.length >= 8 < 50,
};

export default validators;
