import React from 'react';

const InputError = ({ children, className = '' }) => {
  return (
    <div
      className={`${className} mt-1.5 text-sm text-red-400`}
      style={{ minHeight: '1.25rem' }}
    >
      {children}
    </div>
  );
};

export default InputError;
