import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { navigate } from 'gatsby';
import LayoutSignUpFarmers from '../../components/layoutSignUpFarmers';
import { H15 } from '../../components/Texts/index';

import login from '../../images/login.png';
import FarmersSignUpForm from '../../components/FarmersSignUpForm';
import agrotoken from '../../images/logo_standard.svg';

const signUpFarmers = () => {
  const intl = useIntl();
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    fetch(process.env.SIGN_UP_FARMERS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formProps),
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        navigate('success');
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <LayoutSignUpFarmers>
      <div className="flex min-h-screen">
        <div className="max-w-sm m-auto px-4">
          <div className="mb-8 w-full flex">
            <img
              src={agrotoken}
              alt="Logo Agrotoken"
              className="inline-block max-h-6"
            />
          </div>
          <div className="mb-8">
            <H15 className="mb-3">
              {intl.formatMessage({ id: 'farmersSignUp.heading' })}
            </H15>
            <p className="mb-6">
              {intl.formatMessage({ id: 'farmersSignUp.text1' })}
            </p>
          </div>
          <FarmersSignUpForm onSubmit={handleSubmit} />
          {error && (
            <p className="mt-1.5 text-sm text-red-400">
              Ocurrió un error procesando el registro. Por favor, inténtalo de
              nuevo más tarde
            </p>
          )}
        </div>
        <div className="hidden lg:block w-1/2">
          <img
            src={login}
            alt="SignUpFarmers"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </LayoutSignUpFarmers>
  );
};

export default signUpFarmers;
