import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import PrimaryButton from '../Buttons/PrimaryButton';

import Input from './Input';

const FarmersSignUpForm = ({ onSubmit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cuit, setCuit] = useState('');
  const [company, setCompany] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validCuit, setValidCuit] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validCompany, setValidCompany] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstSubmit(true);
    if (validEmail && validName && validCuit) {
      setLoading(true);
      onSubmit(e);
    }
  };

  return (
    <form className="grid grid-cols-1 gap-x-6" onSubmit={handleSubmit}>
      <Input
        type="text"
        label="farmersSignUp.name"
        placeholder="farmersSignUp.name.placeholder"
        errorMessage={intl.formatMessage({ id: 'farmersSignUp.error.name' })}
        emptyMessage={intl.formatMessage({ id: 'farmersSignUp.error.empty' })}
        value={name}
        setValue={setName}
        setIsValid={setValidName}
        isValid={validName}
        firstSubmit={firstSubmit}
        validate
      />
      <Input
        type="text"
        label="farmersSignUp.email"
        placeholder="farmersSignUp.email.placeholder"
        errorMessage={intl.formatMessage({ id: 'farmersSignUp.error.email' })}
        emptyMessage={intl.formatMessage({ id: 'farmersSignUp.error.empty' })}
        value={email}
        setValue={setEmail}
        setIsValid={setValidEmail}
        isValid={validEmail}
        firstSubmit={firstSubmit}
        validate
      />
      <Input
        type="text"
        label="farmersSignUp.company"
        placeholder="farmersSignUp.company.placeholder"
        errorMessage={intl.formatMessage({ id: 'farmersSignUp.error.company' })}
        emptyMessage={intl.formatMessage({ id: 'farmersSignUp.error.empty' })}
        value={company}
        setValue={setCompany}
        setIsValid={setValidCompany}
        isValid={validCompany}
        firstSubmit={firstSubmit}
        validate
      />
      <Input
        type="text"
        label="farmersSignUp.cuitNumber"
        placeholder="farmersSignUp.cuitNumber.placeholder"
        errorMessage={intl.formatMessage({ id: 'farmersSignUp.error.cuit' })}
        emptyMessage={intl.formatMessage({ id: 'farmersSignUp.error.empty' })}
        value={cuit}
        setValue={setCuit}
        setIsValid={setValidCuit}
        isValid={validCuit}
        firstSubmit={firstSubmit}
        validate
      />

      <div className="grid-cols-none flex space-x-2 order-last mt-3">
        <PrimaryButton
          type="submit"
          text={intl.formatMessage({ id: 'farmersSignUp.submit' })}
          loading={loading}
        />
      </div>
    </form>
  );
};
export default FarmersSignUpForm;

FarmersSignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
